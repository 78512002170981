import { TodoList, Todo } from "./todo"; //Import TodoList-class and Todo-interface

//Get DOM-elements
const addTaskFormButtonEl = document.getElementById('addTask'); //Get "Lägg till uppgift" button to render form
const todoContainerEl = document.getElementById('todoContainer'); //Get todo-container
const formContainerEl = document.getElementById('formContainer'); //Get form-container


//Add eventlisterns
addTaskFormButtonEl?.addEventListener('click', renderForm); //Add eventlistener to render input-form


//Get id from parent element
function getParentID(e:HTMLElement): number{
    const target = e.parentElement as HTMLElement; //Get target
    const targetID: number = parseInt(target.id); //Get target id as a number
    return targetID;
}


//Handle formdata
function handleInput(event: SubmitEvent) {
    const addTaskForm = document.getElementById('taskForm') as HTMLFormElement; //Get course form element

    //Prevent form submission
    event.preventDefault();

    //Set formdata and create empty object
    const formData = new FormData(event.target as HTMLFormElement);
    const formDataObject: { [key: string]: string } = {};
    
    //Store formdata as key:value in formDataObject
    for (const [key, value] of formData.entries()) {
        formDataObject[key] = String(value);
    }

    //Convert string to number
    const priority: number = parseInt(formDataObject.priority);

    //Create objcet with Todo-interface
    let newTodoObject: Todo = {
        task: formDataObject.task,
        completed: false,
        priority: priority as 1 || 2 || 3
    }

    //Add new todo
    let newTodo = TodoList.addTodo(newTodoObject);

    //If true, render new list, else show error
    if (newTodo === true){
        renderList();
        clearForm(addTaskForm);
    } else {
        window.alert("Todo kunde inte skapas, fyll i uppgiften samt välj prioritet från listan (1-3)");
    }
} 


//Render todo-items to page and add eventlisteners
function renderList(){
    TodoList.loadFromLocalStorage(); //Load todos from localstorage
    const todos = TodoList.getTodos(); //Get todos

    //If there is todos, render todo-items
    if (todos.length > 0 && todoContainerEl){
        todoContainerEl.innerHTML = ""; //Clear todolist
        //Render new todo-items
        todos.forEach((todo, index) => {
            todoContainerEl.innerHTML += `
            <div class="todo__item completed--${todo.completed}" id="${index}">
            <input type="checkbox" name="completed" ${todo.completed ? 'checked' : ''} aria-checked="${todo.completed}">
            <p class="todo__item--details"><strong>${todo.task}</strong></p>
            <button class=" delete--task" aria-label="Radera ${todo.task}" role="button"><i class="ri-delete-bin-2-line"></i></button>
            </div>
            `;
        })
        todoContainerEl.innerHTML += '<button id="clearTasks" class="delete">Rensa lista</button>';

        //Get HTMLElements created above
        const checkboxes = document.querySelectorAll("input[type='checkbox']");
        const deleteButtons = document.querySelectorAll('.delete--task');
        const clearTodosButtonEl = document.getElementById('clearTasks'); //Get "Rensa lista" button to clear todo-list
        
        //Eventlistener to clear list
        clearTodosButtonEl?.addEventListener('click', clearTodos);


        //Eventlisteners for checkbox
        checkboxes?.forEach((checkbox) => {
            checkbox.addEventListener("change", (e) => {
                const target = e.target as HTMLInputElement; //Get target
                if (target){
                    const state: boolean = target.checked; //Get checkbox state
                    const parentID: number = getParentID(target); //Get parent element id
                    if (state || state === false && parentID >= 0) {
                        TodoList.toggleTodoState(parentID, state); //Toggle task as completed/not completed
                        renderList(); //Render new list
                    }
                }
            })
        });

        //Eventlistners for deletebuttons
        deleteButtons?.forEach((btn) => {
            btn.addEventListener("click", (e) => {
                const target = e.currentTarget as HTMLElement; //Get target
                if (target){
                    const parentID: number = getParentID(target); //Get parent element id
                    if (parentID >= 0) {
                        let confirm : boolean = window.confirm(`Är du säker på att du vill ta bort uppgiften?`) //Confirm deletion
                        if (confirm){
                        TodoList.removeTodo(parentID); //Remove todo-item
                        renderList(); //Render new list
                    } else{
                        return;
                    }
                    }
                } 
            })
        });
    } else { //If no todo-items render text 
        if (todoContainerEl){
            todoContainerEl.innerHTML = "Det finns inga uppgifter just nu.";
        }
    }

}


//Render input form for tasks
function renderForm(){
    if (formContainerEl){
        //Form HTML-code
        formContainerEl.innerHTML = `
        <form class="form" id="taskForm">
            <div class="form__group">
                <label for="formTask">Uppgift</label>
                <input type="text" id="formTask" name="task" required>
            </div>
            <div class="form__group"> 
                <label for="formPriority">Prioritet:</label>
                <select id="formPriority" name="priority" required>
                    <option value="" disabled selected>Välj Prioritet</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>
            </div>
            <button type="submit" class="add">Lägg till Uppgift</button>
        </form>`;
    
    //Get form element and add eventlistners
    const form = document.getElementById('taskForm');
    form?.addEventListener('submit', handleInput);
    
    //Create buttons and add eventlisteners
    const closeBtn = document.createElement('button');
    closeBtn.innerText = 'Avbryt';
    closeBtn.id = 'closeForm';
    closeBtn.addEventListener('click', clearDiv)
    form?.appendChild(closeBtn);
    }
}


//Function do clear a specific div
function clearDiv(div : Event) : void {
    const target = div.target as HTMLElement;
    const targetParent = target.closest('div'); //Find parent div
    if (targetParent){
        targetParent.innerHTML = ""; //Clear parent div
    }
}


//Clear form after submisson
function clearForm(form: HTMLFormElement) : void {
    // Iterate through all form elements
    for (const element of form.elements) {
        // Check if element is an input, select, or textarea
        if (element instanceof HTMLInputElement || element instanceof HTMLSelectElement || element instanceof HTMLTextAreaElement) {
            // Clear value of element
            element.value = '';
        }
    }
}


//Clear todo-list
function clearTodos() {
    let confirm : boolean = window.confirm(`Är du säker på att du vill rensa hela listan?`) //Confirm deletion
    if (confirm){
    localStorage.clear();
    renderList();
    } else {
        return;
    }
}


//Call function to render todo-items list
document.addEventListener("DOMContentLoaded", renderList);