//Todo-interface
export interface Todo {
    task: string,
    completed: boolean,
    priority: 1 | 2 | 3
  }

//Create TodoList class
export class TodoList implements Todo {
    task: string;
    completed: boolean;
    priority: 1 | 2 | 3;

    //Todo-array
    private static todos: Todo[] = [];

    //Constructor
    constructor(task: string, completed: boolean, priority: 1 | 2 | 3) {
        this.task = task;
        this.completed = completed;
        this.priority = priority;
    }

    //Add todo-item/task, returns true/false
    public static addTodo(todo: Todo): boolean{
        //If object has correct form save item to array
        if (typeof todo.task === 'string' && todo.task.length > 0 && typeof todo.completed === 'boolean' && [1, 2, 3].includes(todo.priority)) {
            this.todos.push(todo); //Add item to array
            this.saveToLocalStorage(); //Save array to local storage
            return true; //Return boolean
        } else {
            return false; //Return boolean
            }
    }

    //Remove todo-item/task
    public static removeTodo(todoIndex: number): void {
        this.todos.splice(todoIndex, 1);
        this.saveToLocalStorage();
    }

    //Toggle todo-item/task state, instead of "markTodoCompleted"
    public static toggleTodoState(todoIndex: number, state: boolean): void{
        //If state is true, else if state is false update Todo
        if (todoIndex >= 0 && state === true){
            const currentTodo = this.todos[todoIndex]; //Get todo-item/task
            const updatedTodo = new TodoList(currentTodo.task, true, currentTodo.priority); //Update todo-item/task
            this.todos.splice(todoIndex, 1, updatedTodo); //Replace todo in array
            this.saveToLocalStorage(); //Save array to localstorage

        } else if (todoIndex >= 0 && state === false){
            const currentTodo = this.todos[todoIndex]; //Get todo-item/task
            const updatedTodo = new TodoList(currentTodo.task, false, currentTodo.priority); //Update todo-item/task
            this.todos.splice(todoIndex, 1, updatedTodo); //Replace todo in array
            this.saveToLocalStorage(); //Save array to localstorage
        }
    } 

    //Get todo array
    public static getTodos(): Todo[] {
        this.todos.sort((a, b) => {
            // Sort based on completion
            if (a.completed && !b.completed) {
                return 1;
            }
            else if (!a.completed && b.completed) {
                return -1;
            }
            // If both are completed or both are not completed, sort by priority
            else {
                if (a.priority > b.priority) {
                    return 1;
                } else if (a.priority < b.priority) {
                    return -1;
                } else {
                    return 0;
                }
            }
        });
        return this.todos;
    }
    
    //Save to localstorage
    public static saveToLocalStorage(): void {
        localStorage.setItem("todos", JSON.stringify(this.todos));
    }

    //Load from localstorage
    public static loadFromLocalStorage(): void {
        this.todos = []; //Clear todo-array
        let localStorageTodos: string | null = localStorage.getItem("todos"); //Get todos from localstorage
        
        //If localstorage has todos add each todo to array
        if (localStorageTodos){
            const loadedTodos : Todo[] = JSON.parse(localStorageTodos);
            if (loadedTodos.length > 0) {
                loadedTodos.forEach(todo => {
                    this.todos.push(todo);
                })
            } else{
                console.log("No todos in localstorage");
            }
        } else{
            console.log("No todos-object in localstorage");
        }
    }

}
